import React from 'react';
import styled from 'styled-components';

import { Box, Grid } from '@core';
import { ArrowIcon, ImageCore, SectionContainerSC as SectionContainer, Text } from '@components';
//importing directly from file or otherwise we get errors in cms and storybook
import { LinkWrapper } from '@components/link';

const StyledLinkWrapper = styled(LinkWrapper)`
  color: ${({ theme }) => theme.colors.fontDefault};
  display: flex;
  height: 100%;

  svg#arrow {
    path:nth-child(1) {
      fill: ${({ theme }) => theme.colors.darkBlue_80};
    }

    path:nth-child(2) {
      fill: ${({ theme }) => theme.colors.darkBlue};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};

    svg#arrow {
      path:nth-child(1) {
        fill: ${({ theme }) => theme.colors.primary_80};
      }

      path:nth-child(2) {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

const LogoList = ({ title, subtitle, description, data }) => (
  <SectionContainer title={title} description={description}>
    <Grid.Container justifyContent="center">
      {subtitle && (
        <Text.Subheading2 mb={20} fontSize={{ _: 20, sm: 22 }}>
          {subtitle}
        </Text.Subheading2>
      )}
      <Grid.Row
        width={{ _: 344, sm: 516, md: 688, lg: 900, xl: 976, xxl: 1220 }}
        my={{ _: 8, lg: 12 }}
      >
        {data?.map(({ title, image, link }, index) => (
          <StyledLinkWrapper key={index} link={link}>
            <Grid.Container
              width={{ _: 156, xl: 220 }}
              minHeight={{ _: 130, xl: 164 }}
              height="auto"
              m={{ _: 8, lg: 12 }}
              p={8}
            >
              <Grid.Row
                justifyContent="center"
                alignContent="center"
                flexDirection="column"
                height={{ _: 54, xl: 80 }}
                maxHeight={{ _: 54, xl: 80 }}
                mb={8}
              >
                {image && (
                  <ImageCore
                    src={image.src}
                    alt={image.alt || title}
                    maxWidth="90%"
                    maxHeight="100%"
                  />
                )}
              </Grid.Row>
              <Box
                minHeight={40}
                display="flex"
                justifyContent="center"
                alignItems="center"
                m="auto"
              >
                <Text.Body3Strong textAlign="center" color={link ? 'inherit' : 'fontDefault'}>
                  {title}
                </Text.Body3Strong>

                {link && (
                  <Box
                    as="span"
                    ml={12}
                    display="flex"
                    alignItems="center"
                    width={7}
                    minWidth={7}
                    height={12}
                  >
                    <ArrowIcon />
                  </Box>
                )}
              </Box>
            </Grid.Container>
          </StyledLinkWrapper>
        ))}
      </Grid.Row>
    </Grid.Container>
  </SectionContainer>
);

export default LogoList;
